<template xmlns="">
	<div class="data-content">
		<div class="top-box">
			<div class="top-left-box">
				<div>
					<button v-if="perms.includes('home:repair:settlement:add')"
							class="add-btn"
							@click="handleAddEdit('add')">新增
					</button>
					<button class="add-btn"
							@click="handleExport"> 导出
					</button>
					<button v-if="perms.includes('home:repair:settlement:deal')"
							class="batch-btn"
							:class="{ disabled: batchReadonly }"
							@click="handleUpdateBatch()">批量结算
					</button>
				</div>
				<label class="label-tips">金额：{{ formatPriceString(sumSettle) }}</label>
			</div>
			<div class="top-right-box">
				<label class="label">日期</label>
				<el-date-picker
					style="margin: 0 10px;width: 280px;"
					v-model="dateRange"
					type="daterange"
					value-format="yyyy-MM-dd"
					range-separator="~"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<label class="label">结算状态</label>
				<el-select class="search-input"
						   size="small"
						   v-model="settleStatus"
						   filterable
						   placeholder="全部">
					<el-option value="0"
							   label="否"></el-option>
					<el-option value="1"
							   label="是"></el-option>
				</el-select>
				<label class="label">关键字</label>
				<el-input class="search-input"
						  size="small"
						  type="text"
						  v-model="searchKey"
						  placeholder="请输入关键字"></el-input>
				<img class="search-btn"
					 src="@/assets/images/sousuo.png"
					 alt=""
					 @click="getDataList">
				<el-button icon="el-icon-refresh-right"
						   class="refresh-btn"
						   @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				@selection-change="handleSelectionChange"
				class="scroll-table"
				@sort-change="handleSortChange"
				:default-sort="defaultSort"
				style="width: 100%;">
				<el-table-column type="selection"
								 width="55"/>
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{ scope.$index + 1 }}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="dealerName"
					label="特约维修店名"
				></el-table-column>
				<el-table-column
					prop="settleAmount"
					label="维修费"
					width="100"
				>
					<template slot-scope="scope">
						{{ formatPriceString(scope.row.settleAmount) }}
					</template>
				</el-table-column>
				<el-table-column
					prop="settleAmount"
					label="其它费用"
					width="100"
				>
					<template slot-scope="scope">
						{{ formatPriceString(scope.row.settleOtherAmount) }}
					</template>
				</el-table-column>
				<el-table-column
					prop="repairFinishDate"
					label="维修完成日期"
					width="130"
					sortable="custom">
					<template slot-scope="scope">
						{{ formatTimestamp(scope.row.repairFinishDate) }}
					</template>
				</el-table-column>
				<el-table-column
					label="结算状态"
					prop="settleStatus"
					width="80">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.settleStatus === 1"
								type="success">是
						</el-tag>
						<el-tag v-else
								type="warning">否
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="serialNumber"
					label="流水号"
					width="150"
					sortable="custom"
				></el-table-column>
				<el-table-column
					prop="machineNo"
					label="机号"
					width="120"
				></el-table-column>
				<el-table-column
					prop="modelName"
					label="机型"
					width="100"
				></el-table-column>
				<el-table-column
					prop="modelCode"
					label="机型编号"
					width="120"
				></el-table-column>
				<el-table-column
					prop="followStatus"
					label="回访状态"
					width="80">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.followStatus === 1"
								type="success">满意
						</el-tag>
						<el-tag v-else-if="scope.row.followStatus === 2"
								type="danger">不满意
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					fixed="right"
					label="操作"
					width="125">
					<template slot-scope="scope">
						<button class="btn-blue"
								@click="handleAddEdit('check',scope.row)">查看
						</button>
						<button v-if="perms.includes('home:repair:settlement:update') && scope.row.settleStatus !== 1"
								class="btn-blue"
								@click="handleAddEdit('edit',scope.row)">编辑
						</button>
						<button v-if="perms.includes('home:repair:settlement:delete') && scope.row.settleStatus !== 1"
								class="btn-red"
								@click="handleDelete(scope.row)">删除
						</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>

		<settle-model ref="settleModel"
					  @refresh="getDataList"></settle-model>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {
	repairTypeOptions,
	serviceTypeOptions,
	repairAuditStatusOptions,
} from "@/constant/options"
import {debounce} from "@/utils/debounce";
import SettleModel from "./components/settleModel.vue"

export default {
	components: {
		Pagination,
		SettleModel
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			// 表格数据
			dataList: [],
			searchKey: '',
			pageNo: 1,
			pageSize: 10,
			total: 0,
			settleStatus: '',
			startTime: '',
			endTime: '',
			sumSettle: '0.00',
			dateRange: '',
			batchReadonly: true,
			checkboxList: [],
			repairTypeOptions,
			serviceTypeOptions,
			repairAuditStatusOptions,
			defaultSort: {
				prop: '',
				order: ''
			},
			// 排序字段
			fieldType: '',
			// 排序规则
			orderType: '',
		}
	},
	created() {
		this.getDataList()
	},
	computed: {
		sortProp() {
			return this.defaultSort.prop
		},
		sortOrder() {
			return this.defaultSort.order
		},
	},
	watch: {
		dateRange(range) {
			if (!range || range.length !== 2) {
				this.startTime = ''
				this.endTime = ''
			} else {
				[this.startTime, this.endTime] = range
			}
		},
		sortProp(prop) {
			if (prop === 'repairFinishDate') {
				this.fieldType = 'repairFinishDate'
			} else if (prop === 'serialNumber') {
				this.fieldType = 'serialNumber'
			} else {
				this.fieldType = ''
			}
		},
		sortOrder(order) {
			if (order === 'ascending') {
				this.orderType = 'asc'
			} else if (order === 'descending') {
				this.orderType = 'desc'
			} else {
				this.orderType = ''
			}
		},
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row, rowIndex}) {
			if (rowIndex % 2 == 1) {
				return 'blue'
			} else {
				return 'yellow'
			}
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		handleAddEdit(type, data) {
			this.$refs.settleModel.init(type, data)
		},
		handleUpdateBatch() {
			if (this.checkboxList.length == 0) {
				this.$message.error('请选择结算信息')
				return
			}
			let that = this;
			that.$request.post(
				"repairRepairBatchSettle",
				true,
				{
					ids: that.checkboxList.join(","),
				},
				function (r) {
					if (r.code == "0") {
						that.$message.success('批量结算成功')
						that.getDataList()
					} else {
						that.$message.error(r.msg)
					}
				},
			)
		},
		handleDelete(data) {
			this.$confirm('确定删除当前结算信息吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this
				that.$request.post(
					"repairRepairSettleDel",
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.$message.success('删除成功')
							that.getDataList()
						}
					}
				)
			}).catch(() => {
			})
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1
			this.searchKey = ''
			this.settleStatus = ''
			this.startTime = ''
			this.endTime = ''
			this.getDataList()
		},
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
		getDataList() {
			let that = this
			that.$request.post(
				"repairRepairSettlePageList",
				true,
				{
					searchKey: that.searchKey,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					startTime: that.startTime,
					endTime: that.endTime,
					settleStatus: that.settleStatus,
					fieldType: that.fieldType,
					orderType: that.orderType
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list
						that.total = r.data.totalCount

						that.getSumSettle()
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		getSumSettle() {
			let that = this
			that.$request.post(
				"repairRepairSumSettle",
				true,
				{
					searchKey: that.searchKey,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					startTime: that.startTime,
					endTime: that.endTime,
					settleStatus: that.settleStatus,
					fieldType: that.fieldType,
					orderType: that.orderType
				},
				function (r) {
					if (r.code == "0") {
						that.sumSettle = r.data
					} else {
						that.$message.error(r.msg)
					}
				}
			)
		},
		formatPriceString(price, unit) {
			if (price === null || price === undefined) return ""
			if (typeof price === "string" && price.trim() === "") return ""
			try {
				const p = parseInt(price)
				if (isNaN(p)) return price
				let s = p.toLocaleString("zh-CN", {style: 'currency', currency: 'CNY'})
				return `${s}${unit || ""}`
			} catch (e) {
				return price
			}
		},
		// 多选
		handleSelectionChange(val) {
			this.batchReadonly = !val.length
			this.checkboxList = val.map(item => item.id)
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"settleExport",
				true,
				{
					searchKey: that.searchKey,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					startTime: that.startTime,
					endTime: that.endTime,
					settleStatus: that.settleStatus,
					fieldType: that.fieldType,
					orderType: that.orderType
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 排序规则变更
		handleSortChange({
							 prop,
							 order
						 }) {
			this.defaultSort.prop = prop
			this.defaultSort.order = order

			this.$nextTick(() => {
				this.getDataList()
			})
		},
	}
}
</script>

<style lang="scss"
	   scoped>
.block {
	display: block;
}

.data-content {
	.top-box {
		// height: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		.top-left-box {
			display: flex;
			flex-direction: column;

			.label-tips {
				margin-top: 10px;
				font-size: 25px;
				color: red;
			}
		}
	}

	.add-btn {
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.batch-btn {
		display: inline-block;
		width: 99px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.batch-btn.disabled {
		pointer-events: none; /* 禁用点击事件 */
		opacity: 0.5; /* 变灰处理 */
		cursor: not-allowed; /* 改变鼠标指针样式 */
	}

	.template-btn {
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}

	.top-right-box {
		display: flex;
		flex-direction: row;
		align-items: center;

		.label {
			font-size: 14px;
			color: #606266;
			margin-right: 5px;
		}

		.search-input {
			width: 138px;
			margin: 0 10px;
		}

		.search-btn {
			width: 32px;
			vertical-align: middle;
			cursor: pointer;
		}

		.refresh-btn {
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}

		::v-deep .el-input__inner {
			height: 32px;
			line-height: 32px;
		}

		::v-deep .el-input__icon {
			line-height: 32px;
		}

		::v-deep .el-range-editor.el-input__inner {
			padding: 0 3px;
		}
	}

	.btn-blue {
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}

	.btn-red {
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}

.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.error-list {
	height: 50vh;
	box-sizing: border-box;
	padding: 20px;
	overflow: auto;

	li {
		list-style: none;
	}
}

.submit-btn {
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}

.role-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.role-dialog /deep/ .el-dialog__body {
	padding: 0 30px !important;
}

.role-dialog /deep/ .el-form-item__label {
	padding: 0 !important;
}

//.role-dialog /deep/ .el-dialog__footer{
//	padding: 20px 20px 40px !important;
//}

.role-dialog {
	.inline-form-item {
		display: flex;
		justify-content: space-between;

		.el-form-item {
			width: 100%;

			&:first-of-type {
				padding-right: 10px;
			}

			&:last-of-type {
				padding-left: 10px;
			}

			.el-select, .el-checkbox {
				width: 100%;
			}
		}

	}
}

</style>

<style>
.el-table__fixed-right::before {
	background-color: transparent !important;
}

.scroll-table {
	border: 1px solid #dcdfe6;
}

.scroll-table .yellow {
	background-color: rgba(255, 255, 255);
}

.scroll-table .blue {
	background-color: rgba(246, 249, 253);
}

</style>
