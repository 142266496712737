<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="750px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm" :validate-on-rule-change="false">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-form-item label="特约维修店名" prop="dealerName">
						<el-input v-model="dataForm.dealerName" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="维修费" prop="settleAmount">
						<el-input v-model="dataForm.settleAmount" type="number" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="其它费用" prop="settleOtherAmount">
						<el-input v-model="dataForm.settleOtherAmount" type="number" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-form-item label="回访状态" prop="followStatus" >
						<el-select v-model="dataForm.followStatus" :disabled="readonly">
							<el-option key="1" :value="1" label="满意"></el-option>
							<el-option key="2" :value="2" label="不满意"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="流水号" prop="serialNumber">
						<el-input v-model="dataForm.serialNumber" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="机号" prop="machineNo">
						<el-input v-model="dataForm.machineNo" :rows="4" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-form-item label="机型" prop="modelName">
						<el-input v-model="dataForm.modelName" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="机型编号" prop="modelCode">
						<el-input v-model="dataForm.modelCode" placeholder="请输入" maxlength="32" :disabled="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="维修完成日期" prop="repairFinishDate">
						<el-date-picker
							:disabled="readonly"
							v-model="dataForm.repairFinishDate"
							type="date"
							value-format="yyyy-MM-dd"
							placeholder="请选择"
							class="full-width"
						></el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-form-item label="结算状态" prop="settleStatus">
						<el-select v-model="dataForm.settleStatus" :disabled="readonly">
							<el-option key="1" :value="1" label="是"></el-option>
							<el-option key="0" :value="0" label="否"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button v-if="!readonly" :loading="submitLoading" class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'

export default {
	data() {
		return {
			title: '新增结算信息',
			visible: false,
			readonly: false,
			editType: 'add',
			submitLoading: false,
			dataForm: {
				id: '',
				dealerName: '',  // 特约维修店名
				settleAmount: '',  // 本次结算金额
				repairFinishDate: '',  // 维修完成日期
				settleStatus: '',  // 结算的状态 0否 1是
				serialNumber: '',  // 流水号
				machineNo: '',  // 机号
				modelName: '',  // 机型
				modelCode: '',  // 机型编号
				followStatus: '', // 回访的状态 1有维修满意 2有维修不满意
				settleOtherAmount: '' // 其它费用
			},
			submitUrl: 'repairRepairSettleAdd',
			dataRule: {
				dealerName: [
					{ required: true, message: '特约维修店名不能为空', trigger: "blur" }
				],
				settleAmount: [
					{ required: true, message: '维修费不能为空', trigger: "blur" }
				],
				repairFinishDate: [
					{ required: true, message: '维修完成日期不能为空', trigger: "blur" }
				],
				settleStatus: [
					{ required: true, message: '结算的状态不能为空', trigger: "blur" }
				],
				serialNumber: [
					{ required: true, message: '流水号不能为空', trigger: "blur" }
				],
				machineNo: [
					{ required: true, message: '机号不能为空', trigger: "blur" }
				],
				modelName: [
					{ required: true, message: '机型不能为空', trigger: "blur" }
				],
				modelCode: [
					{ required: true, message: '机型编号不能为空', trigger: "blur" }
				],
				followStatus: [
					{ required: true, message: '回访的状态不能为空', trigger: "blur" }
				],
				settleOtherAmount: [
					{ required: true, message: '其它费用不能为空', trigger: "blur" }
				]
			},
		}
	},
	created() {
	},
	methods: {
		init(type, data) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.editType = type;
				this.dataForm ={
					...data,
					repairFinishDate: data && data.repairFinishDate ? this.formatTimestamp(data.repairFinishDate) : ''				}
				if (type === 'add') {
					this.title = '新增结算信息';
					this.submitUrl = 'repairRepairSettleAdd';
					this.readonly = false;

					this.dataForm.settleOtherAmount = "0" // 默认值显示
					delete this.dataForm.id;
				} else if (type === 'edit') {
					this.title = '修改结算信息';
					this.submitUrl = 'repairRepairSettleUpdate';
					this.readonly = false;
				} else {
					this.title = '查看结算信息';
					this.submitUrl = '';
					this.readonly = true;
				}
			})
		},
		submit: debounce(function () {
			if (this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.submitLoading = true;
					that.$request.post(
						that.submitUrl,
						false,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('refresh');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
							that.submitLoading = false;
						}
					);
				} else {
					return false;
				}
			});
		}, 1000),
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
	}
}
</script>
<style lang="scss" scoped>
.dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.el-form-item {
	margin-bottom: 10px;

	.el-select {
		width: 100%;
	}
}

.submit-btn {
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}

.role-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.role-dialog /deep/ .el-dialog__body {
	padding: 0 20px !important;
}

.role-dialog /deep/ .el-form-item__label {
	padding: 0 !important;
}

.role-dialog /deep/ .el-dialog__footer {
	padding: 20px 20px 30px !important;
}
</style>
